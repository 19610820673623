<template>
  <div>
    <CRow>
      <CCol sm="12">
        <ProductsTable
          v-if="!is_mobile"
          :items="products"
          hover
          striped
          border
          small
          caption="Productos"
          @refresh="refreshItems"
          :sizes="sizes"
          :tags="tags"
          :colors="colors"
          :materials="materials"
          :styles="styles"
          :categories="categories"
          :types="types"
        />
        <CCard v-if="is_mobile" style="width: 100%; max-width: 600px;">
          <CCardHeader>
            Productos
            <CButton @click="storeModal" color="info" style="float: right;" size="sm">Nuevo Producto</CButton>
          </CCardHeader>
          <CCardBody>
            <CCardText>
              <CInput
                :lazy="false"
                id="name"
                name="name"
                v-model="search"
                placeholder="id, nombre, precio, etc."
              />
              <CButton @click="searching" color="success" style="float: right;" size="sm">Buscar</CButton>
            </CCardText>
          </CCardBody>
        </CCard>
        <CCard v-if="is_mobile" style="width: 100%; max-width: 600px;" v-for="product in products">
          <CCardHeader>
            <span v-if="product.thumb_image" style="float: right; font-size: 14px;">ID: {{product.id}}</span><br>
            <img v-if="product.thumb_image" style="width: 100%; max-width: 600px;" :src='product.thumb_image'>
            <span v-if="!product.thumb_image">NO IMAGE</span>
            <span v-if="!product.thumb_image" style="float: right; font-size: 14px;">ID: {{product.id}}</span><br>
          </CCardHeader>
          <CCardBody>
            <CCardTitle>
              {{product.name}}
            </CCardTitle>
            <CCardText>
              <i v-if="product.category_name && product.type_name">{{product.category_name}} / {{product.type_name}}</i>
              <span v-if="product.sizes_label"><br />Tallas: {{product.sizes_label}}</span>
              <span style="float: right;">$ {{product.price | currency}}</span>
              <br />
              <br />
              <CButton @click="deleteModal(product)" color="danger" style="float: left;" size="sm">Eliminar</CButton>
              <CButton @click="updateModal(product)" color="warning" style="float: right;" size="sm">Editar</CButton>
            </CCardText>
          </CCardBody>
        </CCard>

        <ProductMobileModal v-if="tags.length > 0 && colors.length > 0 && materials.length > 0" ref="productMobileModal" :sizes="sizes" :tags="tags" :colors="colors" :materials="materials" @store="storeProcess" @update="updateProcess"></ProductMobileModal>
        <DeleteModal ref="deleteModal" @delete="deleteProcess"></DeleteModal>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import ProductsTable from '../../components/products/ProductsTable.vue'
import ProductMobileModal from '../../components/products/ProductMobileModal.vue'
import DeleteModal from '@/components/global/DeleteModal.vue'

import products from '../../services/products';
import sizes from '../../services/sizes';
import tags from '../../services/tags';
import colors from '../../services/colors';
import materials from '../../services/materials';
import styles from '../../services/styles';
import categories from '../../services/categories';
import types from '../../services/types';
import store from '../../store'

export default {
  name: 'Products',
  components: { ProductsTable, ProductMobileModal, DeleteModal },
  data: function () {
		return {
            is_mobile: false,
            products: [],
            sizes: [],
            tags: [],
            colors: [],
            materials: [],
            styles: [],
            categories: [],
            types: [],
            search: '',
        }
  },
  mounted: async function() {
    this.loading();

    var w = window.innerWidth;
    var h = window.innerHeight;

    if(w < 800){
      this.is_mobile = true;

      let response = await products.getByMobile();

      if(response.type == "success"){
        this.products = response.data;
      }
    }
    else{
      let response = await products.get();

      if(response.type == "success"){
        this.products = response.data;
      }
    }

    let response2 = await sizes.get();

    if(response2.type == "success"){
      this.sizes = response2.data;
    }
    
    response2 = await sizes.list();

    if(response2.type == "success"){
      this.sizes = response2.data;
    }

    let response3 = await tags.list();

    if(response3.type == "success"){
      this.tags = response3.data;
    }

    let response4 = await colors.list();

    if(response4.type == "success"){
      this.colors = response4.data;
    }

    let response5 = await materials.list();

    if(response5.type == "success"){
      this.materials = response5.data;
    }

    let response6 = await types.list();

    if(response6.type == "success"){
      this.types = response6.data;
    }

    this.loaded();
  },
  methods: {
    async refreshItems (items){
      this.loading();
      this.products = await items;
      this.loaded();
    },
    async searching(){
      this.loading();
      let response = await products.getSearching(this.search);

      if(response.type == "success"){
        this.refreshItems(response.data);
      }

      this.loaded();
    },
    storeModal () {
      this.loading();

      this.$refs.productMobileModal.storeModal();
    },
    updateModal (item) {
      this.loading();

      this.$refs.productMobileModal.updateModal(item);
    },
    deleteModal (item) {
      this.loading();

      this.$refs.deleteModal.show("Eliminar Producto", item.id, item.name);
    },
    async storeProcess(data) {
      let response = await products.store(data);
      
      if(response.type == "success"){
        let response2 = await products.getByMobile();

        if(response2.type == "success"){
          this.refreshItems(response2.data);
        }
      }

      this.showToast(response.type, response.message);
      this.loaded();
    },
    async updateProcess(data) {
      let response = await products.update(data);

      if(response.type == "success"){
        let response2 = await products.getByMobile();

        if(response2.type == "success"){
          this.refreshItems(response2.data);
        }
      }

      this.showToast(response.type, response.message);
      this.loaded();
    },
    async deleteProcess(id) {
      let response = await products.delete({id: id});
      
      if(response.type == "success"){
        let response2 = await products.getByMobile();

        if(response2.type == "success"){
          this.refreshItems(response2.data);
        }
      }

      this.showToast(response.type, response.message);
      this.loaded();
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
