<template>
    <CModal
      :title="title"
      :color="color"
      size="lg"
      :show.sync="shareModal"
    >   <CRow>
          <CCol style="text-align: center; font-size: 24px;">
            {{link}}
          </CCol>
        </CRow>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="closeModal" :color="color">Aceptar</CButton>
        </template>
    </CModal>
</template>

<script>

export default {
  name: 'ShareModal',
  components: {
  },
  props: {

  },
  data () {
    return {
      shareModal: false,
      title: "Compartir",
      color: "info",
      link: "LINK"
    }
  },
  mounted: async function() {
    
  },
  methods: {
    openModal (url) {      
      this.shareModal = true;
      this.link = url;
    },
    closeModal () {
      this.shareModal = false;
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>