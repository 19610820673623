<template>
    <CModal
      :title="title"
      :color="color"
      size="lg"
      :show.sync="productoModal"
    >   
        <CForm id="product_form" method="POST" enctype="multipart/form-data">  
            <CRow>
              <CCol md="6">
                <CInput
                  label="Nombre:"
                  :lazy="false"
                  id="name"
                  name="name"
                  :value.sync="$v.form.name.$model"
                  :isValid="checkIfValid('name')"
                  placeholder=""
                  autocomplete="given-name"
                  invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
                />
              </CCol>
              <CCol md="3">
                <CInput
                  id="price"
                  name="price"
                  label="Precio:"
                  :lazy="false"
                  :value.sync="$v.form.price.$model"
                  :isValid="checkIfValid('price')"
                  placeholder=""
                  invalidFeedback="Este campo es requerido debe ser un valor numérico."
                />
              </CCol>
              <CCol md="3">
                <div role="group" class="form-group">
                  <CSelect
                    label="Status"
                    id="status"
                    name="status"
                    :value.sync="$v.form.status.$model"
                    :options="[{label: 'Proximamente', value: 'is_coming'}, {label: 'Disponible', value: 'active'}, {label: 'Remate', value: 'outlet'}, {label: 'Agotado', value: 'inactive'}]"
                  />
                </div>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="4">
                <CSelect
                  label="Categoría"
                  id="category_id"
                  name="category_id"
                  @change="changeCategory"
                  :value.sync="$v.form.category_id.$model"
                  :options="categoriesOptions"
                  invalidFeedback="Este campo es requerido."
                />
              </CCol>
              <CCol md="4">
                <CSelect
                  label="Tipo"
                  id="type_id"
                  name="type_id"
                  :value.sync="$v.form.type_id.$model"
                  :options="typesOptions"
                  invalidFeedback="Este campo es requerido."
                />
              </CCol>
              <CCol md="4">
                <CSelect
                  label="Estilo"
                  id="style_id"
                  name="style_id"
                  :value.sync="$v.form.style_id.$model"
                  :options="stylesOptions"
                  invalidFeedback="Este campo es requerido."
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol col="6">
                <label>Colores</label>
                <CMultiSelect v-if="productoModal" :key="render" :options="colors" :selected="selected_colors" @update="changeColors" selectionType="tags" :search="true" searchPlaceholder="">></CMultiSelect>
              </CCol>
              <CCol col="6">
                <label>Materiales</label>
                <CMultiSelect v-if="productoModal" :key="render + 1" :options="materials" :selected="selected_materials" @update="changeMaterials" selectionType="tags" :search="true" searchPlaceholder="">></CMultiSelect>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12"><br />
                <CInput
                    type="file"
                    name="image"
                    id="image"
                    label="Imagen del Producto:"
                    :lazy="false"
                    :value.sync="$v.form.image.$model"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12" class="sizeslist">
                <CDataTable
                  hover
                  border
                  fixed
                  :items="form.sizes"
                  :fields="fields"
                  :items-per-page="30"
                  pagination
                  size="sm"
                  :header="false"
                  v-if="show_sizes"
                >
                  <template #name="{item}"><td class="text_small">{{item.name}}</td></template>
                  <template #actions="{item}">
                    <td class="w50 switch">
                      <CSwitch
                          v-model="item.selected"
                          :checked.sync="item.selected"
                          class="mx-1"
                          color="info"
                          size="lg"
                        />
                    </td>
                    <td class="w50 text_small">
                      <CInput
                        size="sm"
                        :value.sync="item.stock"
                        :disabled="!item.selected"
                      />
                    </td>
                  </template>
                </CDataTable>
              </CCol>
            </CRow>
            <CRow>
              <CCol col="12">
                <label>Etiquetas</label>
                <CMultiSelect v-if="productoModal" :key="render + 2" :options="tags" :selected="selected_tags" @update="changeTags" selectionType="tags" :search="true" searchPlaceholder="">></CMultiSelect>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <br />
                <div role="group" class="form-group">
                  <label>Fecha Pedido:</label>
                  <v-date-picker
                    id="order_date"
                    name="order_date"
                    class="date-picker"
                    v-model="$v.form.order_date.$model"
                    locale="es-MX" size="sm"
                    :maxDate="optionsDate.maxDate"
                  />
                </div>
              </CCol>
              <CCol md="6">
                <br />
                <div role="group" class="form-group">
                  <label>Fecha Recibido:</label>
                  <v-date-picker
                    id="date"
                    name="date"
                    class="date-picker"
                    v-model="$v.form.date.$model"
                    :isValid="checkIfValid('date')"
                    locale="es-MX" size="sm"
                    :maxDate="optionsDate.maxDate"
                    invalidFeedback="Este campo es requerido y debe ser una fecha válida."
                  />
                </div>
              </CCol>
            </CRow>
          </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="store" :color="color">Aceptar</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { DatePicker } from 'v-calendar'
import { required, decimal, minLength, email, sameAs, helpers } from "vuelidate/lib/validators" 
import ws from '../../services/categories';
import types_ws from '../../services/types';
import styles_ws from '../../services/styles';
import tags_ws from '../../services/tags';
import colors_ws from '../../services/colors';
import materials_ws from '../../services/materials';

import store from '../../store'

import moment from 'moment';
import moment_timezone from 'moment-timezone'

moment.tz.setDefault('America/Mexico_city')

let today = new moment();
today = new moment(today.format("YYYY-MM-DD HH:m:ss")); 
let today2 = new moment(today.format("YYYY-MM-DD HH:m:ss")).subtract(1, 'month');

export default {
  name: 'ProductModal',
  components: {
    'v-date-picker': DatePicker
  },
  props: {
    sizes: Array,
    tags: Array,
    colors: Array,
    materials: Array
  },
  data () {
    return {
      productoModal: false,
      title: "Nuevo Producto",
      color: "info",
      isEdit: false,
      submitted: false,
      form: this.getEmptyForm(),
      selected_tags: [],
      selected_colors: [],
      selected_materials: [],
      fields: [
        {key: 'name', label: 'Producto'},
        {key: 'actions', label: '', class: 'actions' }
      ],
      optionsDate: {
        maxDate: today.toDate(),
        minDate: today2.toDate()
      },
      categories: [],
      categoriesOptions: [],
      types: [],
      typesOptions: [],
      styles: [],
      stylesOptions: [],
      render: 1,
      show_sizes: true
    }
  },
  mounted: async function() {
    let response3 = await ws.get(); 

    if(response3.type == "success"){
      this.categories = response3.data;
    }

    this.categoriesOptions = await this.$parseSelectOptionsOrdered(this.categories, "id", "name");

    this.sizes.forEach(size => {
        size.selected = false;
        size.code = size.text;
        size.name = size.text;
    });

    this.form = {
      id: 0,
      name: '',
      category_id: 0,
      style_id: 0,
      type_id: 0,
      date: '',
      order_date: '',
      description: '',
      cost: "",
      price: "",
      image: "",
      sizes: this.sizes,
      tags: [],
      colors: [],
      status: 'active'
    };

    this.form.category_id = this.categoriesOptions[0].value;

    let response4 = await types_ws.getByCategory(this.form.category_id); 

    if(response4.type == "success"){
      this.types = response4.data;
    }

    this.typesOptions = await this.$parseSelectOptionsOrdered(this.types, "id", "name");

    let response5 = await styles_ws.get(); 

    if(response5.type == "success"){
      this.styles = response5.data;
    }

    this.stylesOptions = await this.$parseSelectOptions(this.styles, "id", "name");
  },
  methods: {
    changeTags (event) {
      this.selected_tags = event;
    },
    changeColors (event) {
      this.selected_colors = event;
    },
    changeMaterials (event) {
      this.selected_materials = event;
    },
    storeModal () {
      let _this = this;
      
      this.productoModal = true;
      this.productoModal = false;
      
      setTimeout(function(){
        _this.productoModal = true;
      }, 1000);

      this.color = "info";
      this.title = 'Nuevo Producto';
      this.isEdit = false;
      this.submitted = false;

      this.sizes.forEach(size => {
        size.selected = false;
        size.stock = "0";
      });

      this.form = {
          id: 0,
          name: '',
          category_id: 0,
          type_id: 0,
          style_id: 0,
          date: new Date(),
          order_date: new Date(),
          description: '',
          cost: "",
          price: "",
          image: "",
          sizes: [],
          status: 'active'
      }

      this.sizes.forEach(size => {
          let tmp = {
            code: size.code,
            name: size.name,
            stock: 0,
            selected: false
          }

          this.form.sizes.push(tmp);
      });

      this.selected_tags = [];
      this.selected_colors = [];
      this.selected_materials = [];
    },
    async updateModal (item) {
      let _this = this;

      this.productoModal = true;
      this.productoModal = false;
      
      setTimeout(function(){
        _this.productoModal = true;
      }, 1000);

      this.color = "warning";
      this.title = 'Editar Producto';
      this.isEdit = true;
      this.submitted = false;

      let date = moment(item.initial_date, "YYYY-MM-DD");
      let order_date = item.order_date != null ? moment(item.order_date, "YYYY-MM-DD") : moment(item.initial_date, "YYYY-MM-DD");

      let response2 = await types_ws.getByCategory(item.category_id); 

      if(response2.type == "success"){
        this.types = response2.data;
      }

      this.typesOptions = await this.$parseSelectOptionsOrdered(this.types, "id", "name");

      let status = item.is_coming ? 'is_comming' : (item.is_outlet ? 'outlet' : (item.is_deleted ? 'inactive' : 'active'))

      this.form = {
          id: item.id,
          name: item.name,
          category_id: item.category_id,
          type_id: item.type_id,
          style_id: item.style_id,
          description: item.description,
          cost: item.cost,
          price: item.price,
          date: date.toDate(),
          order_date: order_date.toDate(),
          sizes: [],
          status: status
      };

      let sizes = item.sizes;

      this.sizes.forEach(size => {
          let perm = this.$findElementByCode(sizes, size.text);

          let tmp = {
            code: size.text,
            name: size.text,
            stock: 0,
            selected: false
          }

          if(perm){
            tmp.selected = true;
            tmp.stock = perm.stock;
          }

          this.form.sizes.push(tmp);
      });

      this.selected_tags = [];
      this.selected_colors = [];
      this.selected_materials = [];

      item.colors.forEach(color => {
          let index = this.$findIndexByText(_this.colors, color);

          _this.selected_colors.push((index * 1 + 1));
      });

      item.tags.forEach(tag => {
          let index = this.$findIndexByText(_this.tags, tag);

          _this.selected_tags.push((index * 1 + 1));
      });

      item.materials.forEach(tag => {
          let index = this.$findIndexByText(_this.materials, tag);

          _this.selected_materials.push((index * 1 + 1));
      });
    },
    store () {
      console.log("store");
      this.$v.$touch();

      console.log(this.isValid);

      if (this.isValid) {
        this.loading();

        const form = document.getElementById("product_form");
        const formData = new FormData(form);

        let date = new moment(this.form.date);
        let order_date = new moment(this.form.order_date);

        console.log(date);

        formData.append("id", this.form.id);
        formData.append("date", date.format("YYYY-MM-DD"));
        formData.append("order_date", order_date.format("YYYY-MM-DD"));

        let _this = this;

        let selection_sizes = [];
        this.form.sizes.forEach(size => {
          if(size.selected !== undefined && size.selected){
            selection_sizes.push(size.name+":"+size.stock);
          }
        });

        let selection_tags = [];
        this.selected_tags.forEach(selected_tag => {
          if(_this.tags[selected_tag - 1] !== undefined){
            let selection = _this.tags[selected_tag - 1].text;
            selection_tags.push(selection);
          }
        });

        let selection_colors = [];
        this.selected_colors.forEach(selected_color => {
          if(_this.colors[selected_color - 1] !== undefined){
            let selection = _this.colors[selected_color - 1].text;
            selection_colors.push(selection);
          }
        });

        let selection_materials = [];
        this.selected_materials.forEach(selected_material => {
          if(_this.materials[selected_material - 1] !== undefined){
            let selection = _this.materials[selected_material - 1].text;
            selection_materials.push(selection);
          }
        });


        formData.append("sizes", selection_sizes);
        formData.append("tags", selection_tags);
        formData.append("colors", selection_colors);
        formData.append("materials", selection_materials);
        formData.append("status", this.form.status);

        
        this.submitted = true;

        if(this.form.id > 0){
          this.$emit("update", formData );
        }
        else{
          this.$emit("store", formData );
        }

        this.closeModal();
      }
    },
    async changeCategory () {
      let response = await types_ws.getByCategory(this.form.category_id); 

      if(response.type == "success"){
        this.types = response.data;
      }

      this.typesOptions = await this.$parseSelectOptionsOrdered(this.types, "id", "name");
    },
    closeModal () {
      this.productoModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    getEmptyForm () {
      return {
          id: 0,
          name: '',
          category_id: 0,
          type_id: 0,
          style_id: 0,
          description: '',
          cost: "",
          price: 0.00,
          date: '',
          order_date: '',
          sizes: [],
          tags: [],
          colors: [],
          image: "",
          status: 'active'
      }
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid || field.$model === '')
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3)
      },
      category_id: {},
      type_id: {},
      style_id: {},
      date: {
        required
      },
      order_date: {},
      description: {},
      cost: {},
      price: {
        required,
        decimal
      },
      image: {},
      tags: [],
      status: {}
    }
  },
}
</script>