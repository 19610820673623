<template>
  <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <CIcon name="cil-https"/> {{caption}}
              <div class="card-header-actions filters">
                <table>
                  <tr>
                    <td v-if="show_filters" style="font-size: 12px; text-align: left; width: 70px;">
                      Filtrar por:
                    </td>
                    <td v-if="show_filters">
                      <CMultiSelect class="filtering" :options="types" :selected="selected_types" @update="changeTypes" selectionType="counter" :selectionTypeCounterText="selected_types !== undefined && selected_types.length > 1 ? 'tipos' : 'tipo'" :search="true" searchPlaceholder="tipos" size="sm">></CMultiSelect>
                    </td>
                    <td v-if="show_filters">
                      <CMultiSelect class="filtering" :options="colors" :selected="selected_colors" @update="changeColors" selectionType="counter" :selectionTypeCounterText="selected_colors !== undefined && selected_colors.length > 1 ? 'colores' : 'color'" :search="true" searchPlaceholder="colores" size="sm">></CMultiSelect>
                    </td>
                    <td v-if="show_filters">
                      <CMultiSelect class="filtering" :options="materials" :selected="selected_materials" @update="changeMaterials" selectionType="counter" :selectionTypeCounterText="selected_materials !== undefined && selected_materials.length > 1 ? 'materiales' : 'material'" :search="true" searchPlaceholder="materiales" size="sm">></CMultiSelect>
                    </td>
                    <td v-if="show_filters">
                      <CMultiSelect class="filtering" :options="sizes" :selected="selected_sizes" @update="changeSizes" selectionType="counter" :selectionTypeCounterText="selected_sizes !== undefined && selected_sizes.length > 1 ? 'tallas' : 'talla'" :search="true" searchPlaceholder="tallas" size="sm">></CMultiSelect>
                    </td>
                    <td v-if="show_filters">
                      <CMultiSelect class="filtering" :options="tags" :selected="selected_tags" @update="changeTags" selectionType="counter" :selectionTypeCounterText="selected_tags !== undefined && selected_tags.length > 1 ? 'etiquetas' : 'etiqueta'" :search="true" searchPlaceholder="etiquetas" size="sm">></CMultiSelect>
                    </td>
                    <td style="width: 10px;"></td>
                    <td v-if="show_filters" style="font-size: 12px; text-align: left; width: 50px;">
                      Buscar:
                    </td>
                    <td v-if="show_filters">
                      <CInput
                        class="searching"
                        :value.sync="search"
                        size="sm"
                        placeholder="id, nombre, precio, etc."
                        @update:value="changeSearch"
                      />
                    </td>
                    <td style="width: 10px;"></td>
                    <td>
                      <CButton color="info" size="sm" @click="storeModal()">Nuevo Producto</CButton>
                    </td>
                  </tr>
                </table>
              </div>
            </slot>
          </CCardHeader>
          <CCardBody>
            <CDataTable
              :hover="hover"
              :striped="striped"
              :border="border"
              :small="small"
              :fixed="fixed"
              :items="items"
              :fields="fields"
              :dark="dark"
            >
              <template #image="{item}"><td class="w100 text-center"><img style="width: 100px;" :src='item.thumb_image'></td></template>
              <template #name="{item}"><td class="text-left">{{item.name}}<br /><i v-if="item.category_name && item.type_name">{{item.category_name}} / {{item.type_name}}</i><span v-if="item.sizes_label"><br /><br />Tallas: {{item.sizes_label}}</span></td></template>
              <template #id="{item}"><td class="w50 text-center">{{item.id}}</td></template>
              <template #cost="{item}"><td class="w100 text-center">{{item.cost | currency}}</td></template>
              <template #price="{item}"><td class="w100 text-center">{{item.price | currency}}</td></template>
              <template #actions="{item}">
                <td class="table_actions">
                  <CButton color="warning" size="sm" @click="updateModal(item)"><CIcon name="cil-pencil"/></CButton>
                  <CButton color="danger" size="sm" @click="deleteModal(item)"><CIcon name="cil-trash"/></CButton>
                </td>
              </template>
            </CDataTable>
          </CCardBody>
          <ProductModal v-if="tags.length > 0 && colors.length > 0 && materials.length > 0" ref="productoModal" :sizes="sizes" :tags="tags" :colors="colors" :materials="materials" @store="storeProcess" @update="updateProcess"></ProductModal>
          <ProductBasicModal ref="productoBasicModal" @store="storeBasicProcess" @update="updateProcess"></ProductBasicModal>
          <DeleteModal ref="deleteModal" @delete="deleteProcess"></DeleteModal>
          <ShareModal ref="shareModal"></ShareModal>
        </CCard>
      </CCol>
      <CCol sm="12">
        <CCard>
          <CCardBody class="text-left">
            <div class="card-header-actions">
              <CButton color="info" size="sm" @click="shareModal()">Compartir</CButton>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
</template>

<script>
import ProductModal from './ProductModal.vue'
import ProductBasicModal from './ProductBasicModal.vue'
import ShareModal from './ShareModal.vue'
import DeleteModal from '../global/DeleteModal.vue'
import ws from '../../services/products';
import store from '../../store' 

export default {
  name: 'ProductsTable',
  components: { ProductModal, ProductBasicModal, ShareModal, DeleteModal },
  props: {
    items: Array,
    sizes: Array,
    tags: Array,
    colors: Array,
    materials: Array,
    types: Array,
    sizes_multiselect: Array,
    fields: {
      type: Array,
      default () {
        return [
          {key: 'id', label: 'Id'},
          {key: 'image', label: 'Imagen'},
          {key: 'name', label: 'Nombre'},
          {key: 'cost', label: 'Costo'},
          {key: 'price', label: 'Precio'},
          {key: 'actions', label: 'Acciones', class: 'actions' },
        ]
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean,
  },
  data() {
    return {
      toasts: [],
      show_filters: false,
      search: '',
      selected_tags: [],
      selected_materials: [],
      selected_colors: [],
      selected_types: [],
      selected_sizes: []
    }
  },
  mounted: async function() {
    let _this = this;
    setTimeout(function(){
        _this.show_filters = true;
      }, 3000);
  },
  methods: {
    storeModal () {
      this.$refs.productoModal.storeModal();
    },
    storeBasicModal () {
      this.$refs.productoBasicModal.storeModal();
    },
    updateModal (item) {
      this.$refs.productoModal.updateModal(item);
    },
    updateBasicModal (item) {
      this.$refs.productoBasicModal.updateModal(item);
    },
    deleteModal (item) {
      this.$refs.deleteModal.show("Eliminar Producto", item.id, item.name);
    },
    async storeProcess(data) {
      let response = await ws.store(data);
      
      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
      this.loaded();
    },
    async updateProcess(data) {
      let response = await ws.update(data);

      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
      this.loaded();
    },
    async deleteProcess(id) {
      let response = await ws.delete({id: id});
      
      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
      this.loaded();
    },
    async storeBasicProcess(data) {
      let response = await ws.store(data);
      
      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
      this.loaded();
    },
    showFilters () {
      this.show_filters = true;
    },
    async changeTags (event) {
      this.selected_tags = event;

      this.changeFiltered();
    },
    async changeColors (event) {
      this.selected_colors = event;

      this.changeFiltered();
    },
    async changeMaterials (event) {
      this.selected_materials = event;

      this.changeFiltered();
    },
    async changeTypes (event) {
      this.selected_types = event;

      this.changeFiltered();
    },
    async changeSizes (event) {
      this.selected_sizes = event;

      this.changeFiltered();
    },
    async changeSearch () {
      this.changeFiltered();
    },
    async changeFiltered(){
      this.loading();
      let response = await ws.getFiltered(this.selected_types, this.selected_colors, this.selected_materials, this.selected_sizes, this.selected_tags, this.search);

      if(response.type == "success"){
        this.$emit("refresh", response.data );

        this.loaded();
      }
    },
    async shareModal () {
      this.loading();

      let response = await ws.shareFiltered(this.selected_types, this.selected_colors, this.selected_materials, this.selected_sizes, this.selected_tags, this.search);

      if(response.type == "success"){
        let data = response.data;
        
        this.$refs.shareModal.openModal(data.url);

        this.loaded();
      }
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
