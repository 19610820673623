<template>
    <CModal
      :title="title"
      :color="color"
      size="lg"
      :show.sync="productoModal"
    >   
        <CForm id="product_basic_form" method="POST" enctype="multipart/form-data">  
            <CRow>
              <CCol md="12">
                <CInput
                  label="Nombre:"
                  :lazy="false"
                  id="name"
                  name="name"
                  :value.sync="$v.form.name.$model"
                  :isValid="checkIfValid('name')"
                  placeholder=""
                  autocomplete="given-name"
                  invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12"><br />
                <CInput
                    type="file"
                    name="image"
                    id="image"
                    label="Imagen del Producto:"
                    :lazy="false"
                    :value.sync="$v.form.image.$model"
                />
              </CCol>
            </CRow>
          </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="store" :color="color">Aceptar</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, decimal, minLength, email, sameAs, helpers } from "vuelidate/lib/validators" 

import store from '../../store'

export default {
  name: 'ProductBasicModal',
  components: {

  },
  props: {
    
  },
  data () {
    return {
      productoModal: false,
      title: "Nuevo Producto",
      color: "info",
      isEdit: false,
      submitted: false,
      form: this.getEmptyForm(),
      fields: [
        {key: 'name', label: 'Producto'},
        {key: 'actions', label: '', class: 'actions' }
      ],
    }
  },
  mounted: async function() {
    this.form = {
      id: 0,
      name: '',
      image: "",
    };
  },
  methods: {
    storeModal () {
      let _this = this;
      
      this.productoModal = true;

      this.color = "info";
      this.title = 'Nuevo Producto';
      this.isEdit = false;
      this.submitted = false;

      this.form = {
          id: 0,
          name: '',
          image: "",
      }

    },
    async updateModal (item) {
      let _this = this;

      this.productoModal = true;

      this.color = "warning";
      this.title = 'Editar Producto';
      this.isEdit = true;
      this.submitted = false;

      this.form = {
          id: item.id,
          name: item.name
      };
    },
    store () {
      this.$v.$touch();

      if (this.isValid) {
        this.loading();

        const form = document.getElementById("product_basic_form");
        const formData = new FormData(form);

        formData.append("id", this.form.id);
   
        this.submitted = true;

        if(this.form.id > 0){
          this.$emit("update", formData );
        }
        else{
          this.$emit("store", formData );
        }

        this.closeModal();
      }
    },
    closeModal () {
      this.productoModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    getEmptyForm () {
      return {
          id: 0,
          name: '',
          image: ""
      }
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid || field.$model === '')
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3)
      },
      image: {},
    }
  },
}
</script>